/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// Déclaration variables
var trigger = jQuery('#hamburger');
var ddmenu = jQuery('#elem'); // Drop Down Mega Menu

var isClosed = true;

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

// POP UP si le navigateur utilisé est IE version 8 ou inférieure
if ( jQuery.browser.msie && jQuery.browser.version < 9 ) {
  alert("Votre version d'internet explorer est obsolète et n'est pas compatible avec notre site internet. Veuillez mettre à jour votre système ou utiliser Chrome ou Firefox.");
}

// Fonction de resize des elements d'un carousel video
function resize_carousel_videos() {
  cadre_video_height = parseInt(jQuery("#ec-ajax-video").height());
  jQuery("#ec-carousel-videos").css("height", cadre_video_height + "px");
}

// Fonction de resize de tous les items d'un carousel d'actus
// et de tous les ec-calltoaction
function carouselNormalization() {
  var items = jQuery('#ec-carousel-actus .ec-single-actu'), //grab all slides
  heights = [], //create empty array to store height values
  tallest; //create variable to make note of the tallest slide
  function normalizeHeights() {
    items.each(function() { //add heights to array
      heights.push(jQuery(this).actual('height'));
    });
    tallest = Math.max.apply(null, heights) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
    items.each(function() {
        jQuery(this).css('min-height',tallest + 'px');
    });
    if(jQuery(window).width() < 768) { // on met en pause le carousel si ecran < 768px
      jQuery('#ec-carousel-actus').carousel('pause');
    } else { // on met en lecture le carousel
      jQuery('#ec-carousel-actus').carousel('cycle');
    }
  }

  var ctas = jQuery('.ec-calltoaction'),
  heightsCTA = [],
  tallestCTA;
  function normalizeHeightsCTAs() {
    ctas.each(function() { //add heights to array
      heightsCTA.push(jQuery(this).actual('outerHeight'));
    });
    tallestCTA = Math.max.apply(null, heightsCTA) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
    ctas.each(function() {
        jQuery(this).css('min-height',tallestCTA + 'px');
    });
  }

  var diapos = jQuery('#myCarousel .carousel-inner .item'),
  heightsDIAPO = [],
  tallestDIAPO;
  function normalizeHeightsDIAPOs() {
    if(jQuery(window).width() < 768) { // on met en pause le carousel si ecran < 768px
      diapos.each(function() { //add heights to array
        heightsDIAPO.push(jQuery(this).actual('outerHeight'));
      });
      tallestDIAPO = Math.max.apply(null, heightsDIAPO) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
      diapos.each(function() {
          jQuery(this).css('min-height',tallestDIAPO + 'px');
      });
    }
  }

  var evtsamoi = jQuery('.ec-bloc-evenement'),
  heightsEVT = [],
  tallestEVT;
  function normalizeHeightsEVTs() {
    evtsamoi.each(function() {
        jQuery(this).css('min-height',jQuery('#ec-bloc-tous-les-evenements').height() + 'px');
    });
  }

  var sameheightYT = jQuery('.yarpp-thumbnail'),
  heightsYT = [],
  tallestYT;
  function normalizeHeightsYT() {
    sameheightYT.each(function() { //add height to array
      heightsYT.push(jQuery(this).actual('outerHeight'));
    });
    tallestYT = Math.max.apply(null, heightsYT) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
    sameheightYT.each(function() {
      jQuery(this).css('min-height',tallestYT + 'px');
    });
  }

  var nb_onglets = jQuery('#nb_onglets').val();
  // on resize les elements de chaque onglet
  var sameheight = [];
  var heightsSH = [];
  var tallestSH = [];

  for(var iter = 1; iter <= nb_onglets; iter++) {
    sameheight[iter-1] = jQuery('.ec-same-height-' + iter);
    heightsSH[iter-1] = [];
  }

/*  function normalizeHeightsSH() {
    for(var iter = 1; iter <= nb_onglets; iter++) {
      sameheight[iter-1].each(function() { //add height to array
        heightsSH[iter-1].push(jQuery(this).parent().actual('height'));
      });
      tallestSH[iter-1] = Math.max.apply(null, heightsSH[iter-1]) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
      sameheight[iter-1].each(function() {
        jQuery(this).css('min-height',tallestSH[iter-1] + 'px');
        jQuery(this).parent('div').css('min-height',tallestSH[iter-1] + 'px');
      });
    }
  }*/
  function normalizeHeightsSH() {
    for(var iter = 1; iter <= nb_onglets; iter++) {
      for(var elem = 0; elem < sameheight[iter-1].length; elem++) {
        heightsSH[iter-1].push(jQuery('.ec-same-height-' + iter + ':eq(' + elem + ')').parent().actual('height'));
      }
      tallestSH[iter-1] = Math.max.apply(null, heightsSH[iter-1]) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
      for(elem = 0; elem < sameheight[iter-1].length; elem++) {
        jQuery('.ec-same-height-' + iter + ':eq(' + elem + ')').css('min-height',tallestSH[iter-1] + 'px');
        jQuery('.ec-same-height-' + iter + ':eq(' + elem + ')').parent('div').css('min-height',tallestSH[iter-1] + 'px');
      }
    }
  }

  var nb_onglets_rv = jQuery('#nb_onglets_rv').val();
  // on resize les elements de chaque onglet
  var sameheightRV = [];
  var heightsRV = [];
  var tallestRV = [];

  for(iter = 1; iter <= nb_onglets_rv; iter++) {
    sameheightRV[iter-1] = jQuery('.ec-same-height-rv-' + iter);
    heightsRV[iter-1] = [];
  }

  function normalizeHeightsRV() {
    for(var iter = 1; iter <= nb_onglets_rv; iter++) {
      for(var elem = 0; elem < sameheightRV[iter-1].length; elem++) {
        heightsRV[iter-1].push(jQuery('.ec-same-height-rv-' + iter + ':eq(' + elem + ')').parent().actual('height'));
      }
      tallestRV[iter-1] = Math.max.apply(null, heightsRV[iter-1]) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
      for(elem = 0; elem < sameheightRV[iter-1].length; elem++) {
        jQuery('.ec-same-height-rv-' + iter + ':eq(' + elem + ')').css('min-height',tallestRV[iter-1] + 'px');
        jQuery('.ec-same-height-rv-' + iter + ':eq(' + elem + ')').parent('div').css('min-height',tallestRV[iter-1] + 'px');
      }
    }
  }

  var nb_onglets_rva = jQuery('#nb_onglets_rva').val();
  // on resize les elements de chaque onglet
  var sameheightRVA = [];
  var heightsRVA = [];
  var tallestRVA = [];

  for(iter = 1; iter <= nb_onglets_rva; iter++) {
    sameheightRVA[iter-1] = jQuery('.ec-same-height-rva-' + iter);
    heightsRVA[iter-1] = [];
  }

  function normalizeHeightsRVA() {
    for(var iter = 1; iter <= nb_onglets_rva; iter++) {
      for(var elem = 0; elem < sameheightRVA[iter-1].length; elem++) {
        heightsRVA[iter-1].push(jQuery('.ec-same-height-rva-' + iter + ':eq(' + elem + ')').parent().actual('height'));
      }
      tallestRVA[iter-1] = Math.max.apply(null, heightsRVA[iter-1]) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
      for(elem = 0; elem < sameheightRVA[iter-1].length; elem++) {
        jQuery('.ec-same-height-rva-' + iter + ':eq(' + elem + ')').css('min-height',tallestRVA[iter-1] + 'px');
        jQuery('.ec-same-height-rva-' + iter + ':eq(' + elem + ')').parent('div').css('min-height',tallestRVA[iter-1] + 'px');
      }
    }
  }

  /*var sameheightRV = jQuery('.ec-same-height-rv'),
  heightsRV = [],
  tallestRV;
  function normalizeHeightsRV() {
    if(jQuery(window).width() >= 768) { // on ne resize les ressources que si l'écran fait plus de 767px
      sameheightRV.each(function() { //add height to array
        heightsRV.push(jQuery(this).parent().actual('height'));
      });
      tallestRV = Math.max.apply(null, heightsRV) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
      sameheightRV.each(function() {
        jQuery(this).css('min-height',tallestRV + 'px');
        jQuery(this).parent('div').css('min-height',tallestRV + 'px');
      });
    }
  }*/

/*  var sameheightRVA = jQuery('.ec-same-height-rva'),
  heightsRVA = [],
  tallestRVA;
  function normalizeHeightsRVA() {
    if(jQuery(window).width() >= 768) { // on ne resize les ressources que si l'écran fait plus de 767px
      sameheightRVA.each(function() { //add height to array
        heightsRVA.push(jQuery(this).parent().actual('height'));
      });
      tallestRVA = Math.max.apply(null, heightsRVA) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
      sameheightRVA.each(function() {
        jQuery(this).css('min-height',tallestRVA + 'px');
        jQuery(this).parent('div').css('min-height',tallestRVA + 'px');
      });
    }
  }*/

  var sameheightTV = jQuery('.ec-same-height-tv'),
  heightsTV = [],
  tallestTV;
  function normalizeHeightsTV() {
    if(jQuery(window).width() >= 768) { // on ne resize les ressources que si l'écran fait plus de 767px
      sameheightTV.each(function() { //add height to array
        heightsTV.push(jQuery(this).parent().actual('height'));
      });
      tallestTV = Math.max.apply(null, heightsTV) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
      sameheightTV.each(function() {
        jQuery(this).css('min-height',tallestTV + 'px');
        jQuery(this).parent('div').css('min-height',tallestTV + 'px');
      });
    }
  }

  if (items.length) {
    normalizeHeights();

    jQuery(window).on('resize orientationchange load', function () {
      tallest = 0;
      heights.length = 0; //reset vars
      items.each(function() {
          jQuery(this).css('min-height','0'); //reset min-height
      });
      normalizeHeights(); //run it again
      resize_carousel_videos();
    });
  }

  if (ctas.length) {
    normalizeHeightsCTAs();

    jQuery(window).on('resize orientationchange load', function () {
      tallestCTA = 0;
      heightsCTA.length = 0; //reset vars
      ctas.each(function() {
          jQuery(this).css('min-height','0'); //reset min-height
      });
      normalizeHeightsCTAs(); //run it again
    });
  }

  if (diapos.length) {
    if(jQuery(window).width() < 768) { // on met en pause le carousel si ecran < 768px
      normalizeHeightsDIAPOs();
    }
    jQuery(window).on('resize orientationchange load', function () {
      tallestDIAPO = 0;
      heightsDIAPO.length = 0; //reset vars
      diapos.each(function() {
          jQuery(this).css('min-height','0'); //reset min-height
      });
      normalizeHeightsDIAPOs(); //run it again
    });
  }

  if (evtsamoi.length) {
    normalizeHeightsEVTs();

    jQuery(window).on('resize orientationchange load', function () {
      tallestEVT = 0;
      heightsEVT.length = 0; //reset vars
      evtsamoi.each(function() {
          jQuery(this).css('min-height','0'); //reset min-height
      });
      normalizeHeightsEVTs(); //run it again
    });
  }

  if (sameheightYT.length) {
    normalizeHeightsYT();

    jQuery(window).on('resize orientationchange load', function () {
      tallestYT = 0;
      heightsYT.length = 0; //reset vars
      sameheightYT.each(function() {
        jQuery(this).css('min-height','0'); //reset min-height
      });
      normalizeHeightsYT(); //run it again
    });
  }

  if (sameheight.length) {
    normalizeHeightsSH();

    jQuery(window).on('resize orientationchange load', function () {
      tallestSH = 0;
      heightsSH.length = 0; //reset vars
      sameheight.each(function() {
          jQuery(this).css('min-height','0'); //reset min-height
          jQuery(this).parent('div').css('min-height','0');
      });
      normalizeHeightsSH(); //run it again
    });
  }

  if (sameheightTV.length) {
    normalizeHeightsTV();

    jQuery(window).on('resize orientationchange load', function () {
      tallestTV = 0;
      heightsTV.length = 0; //reset vars
      sameheightTV.each(function() {
          jQuery(this).css('min-height','0'); //reset min-height
          jQuery(this).parent('div').css('min-height','0');
      });
      normalizeHeightsTV(); //run it again
    });
  }

  if (sameheightRV.length) {
    normalizeHeightsRV();

    jQuery(window).on('resize orientationchange load', function () {
      tallestRV = 0;
      heightsRV.length = 0; //reset vars
      sameheightRV.each(function() {
          jQuery(this).css('min-height','0'); //reset min-height
          jQuery(this).parent('div').css('min-height','0');
      });
      normalizeHeightsRV(); //run it again
    });
  }

  if (sameheightRVA.length) {
    normalizeHeightsRVA();

    jQuery(window).on('resize orientationchange load', function () {
      tallestRVA = 0;
      heightsRVA.length = 0; //reset vars
      sameheightRVA.each(function() {
          jQuery(this).css('min-height','0'); //reset min-height
          jQuery(this).parent('div').css('min-height','0');
      });
      normalizeHeightsRVA(); //run it again
    });
  }
}


// Une fois la page chargée...
// ===========================
jQuery(document).ready(function() {

  // Ajuste la taille des items d'un carousel d'actus
  carouselNormalization();

  // on vire les pictos glyphicon-link des liens qui possèdent des images
  jQuery("img").parents('.ec-single-content a').addClass("no-link-icon");

  // Mise en pause du carousel sur les pages rubriques
  if (jQuery('#media').length) {
    jQuery('#media').carousel({
      pause: true
    });
  }

  // Ajuste la taille du player vidéo au chargement de la page
  resize_carousel_videos();

  // Définition du l'interval pour le carousel
  if (jQuery('.carousel').length) {
    jQuery('.carousel').carousel({
      interval: 8000
    });
  }

  /* A GARDER AU CAS OU NOUS AYONS UN JOUR PLUSIEURS CAROUSEL PAR PAGE */
  /*jQuery('#media.carousel').each(function(){
    jQuery(this).carousel({
        pause: true
    });
  });

  jQuery('.carousel').each(function(){
    jQuery(this).carousel({
        interval: 2000
    });
  });*/

  // Definition du clic n'importe où dans la page pour fermeture MENU HAMBURGER
  // ==========================================================================
  jQuery(document).click(
    function(e) {
      // on active le clic uniquement si la taille de l'écran est supérieur ou égale à 768px
      if((jQuery(window).width() >= 768) || (e.pageY < 100)) {
        if ((jQuery('.slider-opacity').css("display") === "block") && (!isClosed)) {
          jQuery('.slider-opacity').fadeOut("slow");
          jQuery('.dropdown-menu').fadeOut("slow");
          jQuery('.dropdown-menu').animate({
            left: '0px',
            top: '-1000px'
          });

          // bouton HAMBURGER animation
          trigger.removeClass('is-open');
          trigger.addClass('is-closed');
          // si la hauteur du menu est supérieure à celle de la fenêtre, on désactive le scroll
          screenheight = parseInt(jQuery(window).height());
          menuheight = parseInt(jQuery("#ddmenu").height()) + 100;
          if(screenheight < menuheight) {
            ddmenu.addClass('navbar-fixed-top');
          }
          isClosed = true;
        }
      }
    }
  );

  // Fonction de clic sur téléphone (<768px) sur item de mega menu
  jQuery(".ec-menu-title").click(
    function() {
      if(jQuery(window).width() < 768) {
        jQuery(this).next("div").toggle("slow", function() {
          // si la hauteur du menu est supérieure à celle de la fenêtre, on active le scroll
          screenheight = parseInt(jQuery(window).height());
          menuheight = parseInt(jQuery("#ddmenu").height()) + 100;
          if(screenheight < menuheight) {
            ddmenu.removeClass('navbar-fixed-top');
          }
        });
      }
    }
  );

  // Définition des tooltips
  // =======================
  jQuery('a[data-toggle="tooltip"]').tooltip({
    animated: 'fade',
    placement: 'right',
    html: true
  });

  // Définition des tooltips
  // =======================
  jQuery(function () {
    jQuery('[data-toggle="popover"]').popover();
  });

}); // fin jQuery(document).ready(function())

// STICKY MENU POUR PAGES RUBRIQUES AVEC effet PARALLAX
// ====================================================
// Effet parallax sur l'image du bandeau de rubrique
if (jQuery('.ec-bandeau-rubrique').length) {
  var jumboHeight = jQuery('.ec-bandeau-rubrique').outerHeight();
}

function parallax() {
  var scrolled = jQuery(window).scrollTop();
  jQuery('.ec-rubrique-bandeau-bg').css('height', (jumboHeight - scrolled) + 'px');
}

parallax();

jQuery(window).on('resize orientationchange load', function () {
  if (jQuery('.ec-bandeau-rubrique').length) {
    jumboHeight = jQuery('.ec-bandeau-rubrique').outerHeight();
  }
  parallax();
  // si on revient à une taille supérieure >=768px alors on affiche les éléments du méga menu
  if(jQuery(window).width() >= 768) {
    jQuery(".ec-menu-title").next("div").show();
  }
});

jQuery(window).scroll(function(e) {
  parallax();
  if (jQuery(this).scrollTop() > jumboHeight) {
    jQuery('.menu-sticky').addClass("sticky");
  } else {
    jQuery('.menu-sticky').removeClass("sticky");
  }
});

jQuery(function($) {
  // GESTION DE LA SCROLL BAR DE L'AGENDA EN PAGE D'ACCUEIL
  // ======================================================
  jQuery(window).on("load",function(){
    if (typeof jQuery(".ec-timeline").mCustomScrollbar === "function") {
      jQuery(".ec-timeline").mCustomScrollbar({
        axis: "y",
        scrollbarPosition: "inside",
        theme: "light",
        scrollButtons: { enable:true }
      });
    }
  });

  // INITIALISATION DES IMAGES à ZOOMER
  // ==================================
  jQuery(document).ready(function(){
    var zooms = jQuery(".ec-zoom");
    zooms.each(function() {
      jQuery(this).elevateZoom({
        zoomType: "inner",
        cursor: "crosshair",
        zoomWindowFadeIn: 500,
        zoomWindowFadeOut: 750
      });
    });
  });

  var sameheightRV = jQuery('.ec-same-height-rv'),
  heightsRV = [],
  tallestRV;
  function normalizeHeightsRV() {
    if(jQuery(window).width() >= 768) { // on ne resize les ressources que si l'écran fait plus de 767px
      sameheightRV.each(function() { //add height to array
        heightsRV.push(jQuery(this).parent().actual('height'));
      });
      tallestRV = Math.max.apply(null, heightsRV) + 1; //cache largest value + 1px pour gestion des décimales sous firefox
      sameheightRV.each(function() {
        jQuery(this).css('min-height',tallestRV + 'px');
        jQuery(this).parent('div').css('min-height',tallestRV + 'px');
      });
    }
  }

  // GESTION DES LIENS INTERNES A LA PAGE (ANCRES) et de l'effet SMOOTH
  // ==================================================================
  jQuery('ul.nav_rubrique li.menu-item a').click(function() {
    // si le lien est une ancre
    if ((location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')) && (location.hostname === this.hostname)) {
      var target = jQuery(this.hash);
      target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        jQuery('html,body').animate({
          scrollTop: target.offset().top - 150
        }, 1000);
        return false;
      }
    }
  });
  // idem sur onLoad
  jQuery(window).on("load",function(){
    if (location.hash) {
      var target = jQuery(location.hash);
      target = target.length ? target : jQuery('[name=' + location.hash.slice(1) + ']');
      if (target.length) {
        jQuery('html,body').animate({
          scrollTop: target.offset().top - 150
        }, 1000);
        return false;
      }
    }
  });

  // CLIC sur BOUTON HAMBURGER DROPDOWN
  // ==================================
  jQuery(document).on('click.bs.dropdown.data-api', '[data-toggle="dropdown"]', function() {
    if (!isClosed) {
      // MENU HAMBURGER OUVERT, ON LE FERME
      // ==================================
      jQuery('.slider-opacity').fadeOut("slow");
      jQuery('.dropdown-menu').fadeOut("slow");
      jQuery('.dropdown-menu').animate({
        left: '0px',
        top: '-1000px'
      });

      // bouton HAMBURGER animation
      trigger.removeClass('is-open');
      trigger.addClass('is-closed');

      // si la hauteur du menu est supérieure à celle de la fenêtre, on désactive le scroll
      screenheight = parseInt(jQuery(window).height());
      menuheight = parseInt(jQuery("#ddmenu").height()) + 100;
      if(screenheight < menuheight) {
        ddmenu.addClass('navbar-fixed-top');
      }
      isClosed = true;
    } else {
      // MENU HAMBURGER FERME, ON L'OUVRE
      // ================================
      jQuery('.slider-opacity').fadeIn("slow");
      jQuery('.dropdown-menu').fadeIn("slow");
      jQuery('.dropdown-menu').animate({
        left: '0px',
        top: '100px'
      });
      jQuery('.slider-opacity').css({
        "opacity": 1,
        "visibility": "visible"
      });

      // bouton HAMBURGER animation
      trigger.addClass('is-open');
      trigger.removeClass('is-closed');

      // si la hauteur du menu est supérieure à celle de la fenêtre, on active le scroll
      screenheight = parseInt(jQuery(window).height());
      menuheight = parseInt(jQuery("#ddmenu").height()) + 100;
      if(screenheight < menuheight) {
        ddmenu.removeClass('navbar-fixed-top');
      }
      isClosed = false;
    }
  });

});
